import api from "./api-service";
import { stringify } from "querystring";

const url = "potential-spaces";

export const find = (filter) => {
  return api().get(`${url}?${stringify(filter)}`);
};

export const create = (potentialSpace) => {
  return api().post(url, potentialSpace);
};

export const update = (potentialSpace) => {
  return api().put(url, potentialSpace);
};

export const findOne = (id) => {
  return api().get(`${url}/${id}`);
};
