<template>
  <div>
    <v-container>
      <perseu-card title="Espaços Potenciais">
        <template slot="title-right">
          <v-btn @click="create" color="secondary">Novo Espaço Potencial</v-btn>
        </template>
        <template slot="content">
          <v-switch v-model="query.active" label="Ativos" @change="find" />
          <v-data-table :items="items" :headers="headers" class="elevation-1">
            <template v-slot:item.actions="{ item }">
              <table-action-button
                text="Editar"
                @click="edit(item)"
                icon="edit"
              />
              <table-action-button
                v-if="item.active"
                text="Desativar"
                @click="activeOrDeactivate(item, false)"
                icon="delete"
              />
              <table-action-button
                v-else
                text="Ativar"
                @click="activeOrDeactivate(item, true)"
                icon="check"
              />
            </template>
          </v-data-table>
        </template>
      </perseu-card>
    </v-container>
    <form-potential-space ref="form" @saved="savedForm" />
  </div>
</template>

<script>
import {
  find,
  create,
  update,
  findOne,
} from "@/services/potential-spaces-service.js";

export default {
  components: {
    FormPotentialSpace: () => import("@/components/PotentialSpaces/Form"),
  },
  data: () => ({
    items: [],
    item: {},
    headers: [
      { text: "Nome", value: "name" },
      {
        text: "Ações",
        value: "actions",
        width: 150,
        sortable: false,
        align: "start",
      },
    ],
    query: {
      active: true,
    },
  }),
  created() {
    this.find();
  },
  methods: {
    async find() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await find(this.query);
        this.items = data;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async activeOrDeactivate(item, option) {
      try {
        this.$store.dispatch("loading/openDialog");
        item.active = option;
        const { data } = await update(item);
        const index = this.findIndexItems(item);
        this.items.splice(index, 1);
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async edit(item) {
      const { data } = await findOne(item.id);
      this.$refs.form.open(data);
    },
    create() {
      this.$refs.form.open({});
    },
    async savedForm(potentialSpaceParam) {
      try {
        this.$store.dispatch("loading/openDialog");
        if (potentialSpaceParam.id) {
          const { data } = await update(potentialSpaceParam);
          const index = this.findIndexItems(data);
          this.items.splice(index, 1, data);
        } else {
          const { data } = await create(potentialSpaceParam);
          this.items.push(data);
        }
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    findIndexItems(itemParam) {
      return this.items.findIndex((item) => item.id === itemParam.id);
    },
  },
};
</script>

<style></style>
